<template>
    <div class="test-repeat">
        <div class="test-repeat__head"> <!-- отображается один из блоков __head -->
            <div class="test-repeat__pic">
                <img class="test-repeat__img" src="~assets/img/favicons/two-list-icon.png" alt="" width="70"
                     height="70">
            </div>
            <div class="test-repeat__title">
                <div v-if="typeTest === 'material'">
                    Для прохождения тестирования ознакомьтесь с новыми статьями и обновлениями<br/><br/>

                    <router-link class="attestation-statistics__more" to="/knowledge/?learn=1">
                        Перейти в раздел "Базы знаний"
                    </router-link>
                </div>

                <div v-if="typeTest === 'document'">
                    Для прохождения тестирования ознакомьтесь c документом<br/><br/>

                    <router-link class="attestation-statistics__more" to="/documents">
                        Перейти в раздел "Документы"
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Study',
    props: {
        typeTest: {
            type: String,
            default: 'material'
        }
    }
};
</script>

<style scoped>

</style>
